.App {
  text-align: center;
}

#WEB3_CONNECT_MODAL_ID {
  z-index: 999;
  position: absolute;
}

#ACCOUNT_HEADER_ID {
  z-index: 900;
}

.artist_blockie {
  border-radius: 50%;
  box-shadow: 0 0 0 1px #b5b5b5, 0 0 0 1px #b5b5b5;
  padding: 5px;
}

.inks-grid li:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
